/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: mfa-preference.css
# Description: This is a custom css style of the Profile MFA configuration
# Created by: Juan David Olivares Padilla
# Creation Date: 11/23/2022

=========================================================
*/
.mfa-preference.disabled {
    color: "#ddd" !important;
    filter: opacity(.6) !important;
}