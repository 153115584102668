/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: copy-to-clipboard.css
# Description: This is a custom css style of the MFA Setup component, providing the styles for the copy-to-clipboard section.
# Created by: Juan David Olivares Padilla
# Creation Date: 11/18/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 11/25/2022

=========================================================
*/
.secretCode {
    background-color: #DCDCDC;
    border-radius: 10px;
    color: #4d4d4d;
    font-style: italic;
    font-family: inherit;
    font-weight: 600;
    width: 100%;
    display: block;
    margin: .5em 0;
    padding: .5em 1em;
    cursor: pointer;
    word-wrap: break-word;
}