/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: table-wrapper.css
# Description: This is a custom css style for the Http codes modal on Audit module
# Created by: Juan David Olivares Padilla
# Creation Date: 2/15/2023

=========================================================
*/
.table_wrapper {
    overflow: scroll;
    height: 75vh;
}
