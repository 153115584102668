.hide-show{
    background-color: #F0F0F0;
    border: 1px solid #bbbb;

}

.invalid-feedback{
    margin-top: 0;
}

.form-control{
    display: inline;
}