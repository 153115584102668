/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: new-password-form.css
# Description: This is a custom css style of the New Password component
# Created by: Juan David Olivares Padilla
# Creation Date: 11/9/2022

=========================================================
*/
.password-policies-list {
    margin-bottom: 0;
    padding-left: 1.25em;
    list-style: '- ';
    font-weight: 300;
}

.password-policies-list li {
    margin-bottom: 2px;
}