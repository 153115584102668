/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: login-recaptcha.css
# Description: This is a custom css style of the Login Google reCAPTCHA checkbox
# Created by: Juan David Olivares Padilla
# Creation Date: 11/11/2022

=========================================================
*/
.recaptcha {
    width: 100%;
    padding-top: 1.5em;
}

.recaptcha div div{
    margin: auto;
}

.custom-control-input-checkbox {
    cursor: pointer;
    background-color: #ddd;
    color: #ddd;
    width: 20px;
    height: 20px;
    appearance: none;
    border: 1px solid #999;
    border-radius: 3px;
    background-position: 0 -2rem;
    background-size: 100%;
    background-repeat: no-repeat;
    transition: all, 0.3s ease-in-out;
    margin: 0 5px;
    vertical-align: sub;
  }
  .custom-control-input-checkbox:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='green' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    background-color: #fff;
    color: #fff;
    background-position: 0 0;
    border: 2px solid #aaaa;
  }

  input.custom-control-input-checkbox:focus-visible{
    border: 2px solid #4d90fe;
  }

  .btn.sso {
    background-image: url('../../img/brand/AzureAD-icon.png');
    background-position: 3.25em center; /* Adjusted left spacing */
    background-repeat: no-repeat;
    background-size: 40px 40px; /* Increased the icon size to 28px */
    padding: 1em 0em 1em 0em; /* Adjusted padding to make space for the larger icon */
    text-align: center; /* Align text to the left */
    font-size: 17.5px;
    font-weight: bold;
    color: #4c4c4c;
    border: 0.5px solid #fff; /* Slightly darker border */
    background-color: #fff;
  }

  .btn.sso:hover, .btn.sso:focus-visible {
    background-color: #d5d5d5; /* White background on hover */
    border: 0.5px solid #ccc; /* Slightly darker border */
  }

  .divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }
  
  .line {
    height: 1px;
    background-color: #ccc;
    flex-grow: 1;
  }
  
  .or {
    margin: 0 1rem;
    color: #999;
  }