/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: bg-gradient.css
# Description: This is a custom css style of Login section background
# Created by: Juan David Olivares Padilla
# Creation Date: 11/18/2022

=========================================================
*/
.bg-gradient-custom2 {
    padding-top: 5.25em;
    background: linear-gradient(180deg, rgba(217,38,50,1) 10%, rgba(249,157,42,1) 70%, rgb(255, 192, 115) 100%) !important;
}

.bg-gradient-custom {
    background: linear-gradient(180deg, rgba(217,38,50,1) 10%, rgba(249,157,42,1) 70%, rgba(255,232,203,1) 100%) !important;
    min-height: 100%;
}

.auth-footer-custom, .auth-footer-custom2{
    text-shadow: 1px 1px 1px #111;
    padding-bottom: 2em;
    position: absolute;
    bottom: 0;
    left: 0;
}

.auth-footer-custom3{
    text-shadow: 1px 1px 1px #111;
    padding-bottom: 2em;
    position: static;
    width: 100%;
}

.image-container {
    position: relative;
    width: 180px;
    margin: 0 auto;
}

.image-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(170, 170, 170, 0.8); /* Gray overlay */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
    box-shadow: 5px 5px 10px #616161;
}

.image-container:hover .image-hover {
    opacity: 1;
}

.image-container:hover .card-img {
    padding: .8em !important;
}

.footer-links {
    color: white;
    cursor: pointer;
    text-shadow: 1px 1px 1px #111;
    text-decoration: underline;
    font-weight: 600;
}

.footer-links:hover {
    color: rgba(217,38,50,1);
    cursor: pointer;
    text-shadow: none;
    text-decoration: none;
    font-weight: 600;
    backdrop-filter: blur(2.5px);
}

@media screen and ( max-height: 650px){
    .auth-footer-custom2{
        position: static;
    }
}

@media screen and ( max-height: 900px){
    .auth-footer-custom{
        position: static;
    }
}