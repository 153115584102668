/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: spinner.css
# Description: This file contains the styles colors, animations applied to the loading spinner
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #f99d2a;; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  background-color: #db2532;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  /*height: 350px;*/
}

.modal-dialog{
  justify-content: center;
}

.modal-loading .modal-content{
  width: 40% !important;
}