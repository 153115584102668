/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: scroll.css
# Description: This is a custom css style for the entire application scroll
# Created by: Juan David Olivares Padilla
# Creation Date: 11/18/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 2/9/2023

=========================================================
*/
.custom-body {
    height: 100vh !important;
    padding-right: 0 !important;
}

.scroll {
    overflow-y: auto !important;
    display: inline-block !important;
}
.scroll--black::-webkit-scrollbar {
    width: 8px !important;
    height: 10px !important;
}
.scroll--black::-webkit-scrollbar-track {
    background: #333 !important;
    box-shadow: 0 0 1px 1px #111, inset 0 0 4px rgba(0, 0, 0, 0.3) !important;
}
.scroll--black::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background: #666 !important;
    box-shadow: inset 0 0 1px 1px #ddd !important;
}

.form-control {
    caret-color: auto;
}