/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: app-card.css
# Description: This is a custom css style animation for the application cards
# Created by: Juan David Olivares Padilla
# Creation Date: 11/2/2022

=========================================================
*/
.main-content{
    height: 100vh;
}

.app-card:hover {
    transform: scale(1.075);
    transition: transform 0.25s ease-in-out;
}

.app-card-disabled {
    cursor: not-allowed !important;
    user-select: none !important;
    pointer-events: none !important;
    border-radius: 10px;
    position: relative;
}

.app-card-disabled:before {
    content: "Maintenance";
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.7);
    color: #D92632;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    font-weight: bold;
    pointer-events: none;
    border-radius: 10px;
}

.app-card-disabled:after {
    content: "";
    position: absolute;
    top: 40%;  /* Adjust these percentages to move the lines */
    bottom: 40%;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, #D92632 10%, transparent 10%, transparent 90%, #D92632 90%) !important;
    pointer-events: none;
}

.app-card-disabled:hover {
    transform: none;
    transition: none
}

.auth-footer-custom, .auth-footer-custom2{
    text-shadow: 1px 1px 1px #111;
    padding-bottom: 2em;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
}

/* @media screen and (max-width: 1789px){
    .main-content{
        height: 100%;
    }
} */

@media screen and (max-width: 1421px){
    .auth-footer-custom{
        position: relative;
    }
}

@media screen and (max-height: 900px){
    .auth-footer-custom{
        position: relative;
    }
}

@media screen and (max-height: 600px){
    .main-content{
        height: 100%;
    }
    .auth-footer-custom2{
        position: relative;
    }
}